import React, { useContext } from 'react';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import ProgressBar from 'common/components/ProgressBar';

const NotConnected: React.FC = () => {
  const { attendeeStepData } = useContext<DashboardContextType>(DashboardContext);
  const liveCandidates = attendeeStepData.filter((candidate) => !['completed', 'terminated'].includes(candidate.status))
  const disconnectedSecondaryCamCount = liveCandidates.reduce((previous, next) => {
    if (next.secondaryCamera?.props?.children === 'OFF') return previous + 1;
    return previous;
  }, 0);
  const disconnectedPrimaryCamCount = liveCandidates.reduce((previous, next) => {
    if (next.primaryCamera?.props?.children === 'OFF') return previous + 1;
    return previous;
  }, 0);
  const disconnectedPrimaryCamPercentage = Math.round((disconnectedPrimaryCamCount / liveCandidates.length) * 100);
  const disconnectedSecondaryCamPercentage = Math.round((disconnectedSecondaryCamCount / liveCandidates.length) * 100);
  return (
    <div className="flex flex-col space-y-6 p-2">
      <ProgressBar
        label="Primary Device"
        color="red"
        value={`${disconnectedPrimaryCamPercentage || 0}%`}
        percentage={disconnectedPrimaryCamPercentage}
      />
      <ProgressBar
        label="Secondary Device"
        color="red"
        value={`${disconnectedSecondaryCamPercentage || 0}%`}
        percentage={disconnectedSecondaryCamPercentage}
      />
    </div>
  );
}

export default NotConnected;

