import React from 'react';

type Props = {
  mainCount: number;
  mainHeader: string;
  subHeader: string;
  subHeaderColor: string;
}

const DetailedHeader: React.FC<Props> = (props) => {
  let mainCount: string | number = props.mainCount;
  if (props.mainCount < 10) mainCount = `0${props.mainCount}`;
  return (
    <div className='flex items-center space-x-2'>
      <div className='text-4xl text-red-700'>{mainCount}</div>
      <div className='flex flex-col items-center'>
        <div className='text-sm font-semibold text-red-700 leading-none'>{props.mainHeader}</div>
        <div className={`text-xs ${props.subHeaderColor}`}>{props.subHeader}</div>
      </div>
    </div>
  );
}

export default DetailedHeader;
