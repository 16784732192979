import React, { useContext, useEffect, useState } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import EmptyState from 'common/components/EmptyState';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { columns, Data } from './TableAttributes';
import { orderBy } from 'lodash';
import moment from 'moment';
import UnreadMessage from './UnreadMessage';
import getStepStatus, { Alert, validAlerts } from './getStepStatus';

const AttendeeStepTable: React.FC = () => {
  const {
    sessions,
    proctors,
    currentProctors,
    candidates,
    attendeeStepData,
    setAttendeeStepData

  } = useContext<DashboardContextType>(DashboardContext);

  const [tableData, setTableData] = useState<Data[]>(attendeeStepData);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  React.useEffect(() => {
    if (sessions) {
      console.log(sessions);
      const result: Data[] = [];
      sessions.forEach((session) => {
        if (proctors && proctors.length && candidates && candidates.length && session.alerts) {
          let alerts: any[] = [];
          const keys = Object.keys(session.alerts);
          keys.forEach((key, i) => {
            alerts.push({
              alert_type_id: session.alerts[key].alert_type_id,
              timestamp: session.alerts[key].updated_at,
              session_id: session.session_id
            });
          });

          alerts = orderBy(
            alerts,
            (o) => {
              return moment(o.timestamp);
            },
            ['desc'],
          );
          const lastOnlineAlerts = [];

          let faceCaptureTimestamp = null;
          let audioDeviceDetectedTimestamp = null;
          const alertMap: Record<number, boolean> = {};
          let i = 0;
          while (i <= alerts.length) {
            if (!alertMap[alerts[i].alert_type_id]) {
              if (alerts[i].alert_type_id === Alert.SessionJoined) {
                lastOnlineAlerts.push(alerts[i]);
                break;
              }
              if (alerts[i].alert_type_id === Alert.FaceCaptureSuccess) {
                faceCaptureTimestamp = alerts[i].timestamp;
              }
              if (alerts[i].alert_type_id === Alert.AudioDeviceDetected) {
                audioDeviceDetectedTimestamp = alerts[i].timestamp;
              }
              if (validAlerts.includes(alerts[i].alert_type_id)) {
                lastOnlineAlerts.push(alerts[i]);
              }
              alertMap[alerts[i].alert_type_id] = true;
            }
            i++;
          }
          const alertIds = lastOnlineAlerts.map((alert) => alert.alert_type_id);
          const stepStatus = getStepStatus(alertIds, faceCaptureTimestamp, session.pre_check);
          const p = proctors.find((proctor) => proctor.participant_group_id === session.participant_group_id);
          const c = candidates.find((candidate) => candidate.id === session.attendee_id);
          if (p && c) {
            result.push({
              ...stepStatus,
              id: session.session_id,
              slotId: session.slot_id,
              status: session.session_status,
              proctor: p.email || null,
              proctorUserId: p.id,
              attendee: c.external_attendee_id,
              attendeeId: c.id,
              participantGroupId: session.participant_group_id,
              primaryCamera: (
                <div
                  className={`${
                    session.session_status === 'online'
                      ? 'text-emerald-700 font-bold'
                      : 'text-red-700 font-bold'
                  }`}
                >
                  {session.session_status === 'online' ? 'ON' : 'OFF'}
                </div>
              ),
              chat: <UnreadMessage message={session.messages} />,
            });
          }
        }
      });
      setTableData(result);
      setAttendeeStepData(result);
    }
  }, [sessions]);

  useEffect(() => {
    const selectedProctors = currentProctors.map((currentProctor) => currentProctor.id);
    if (selectedProctors.length) {
      const filteredProctors = attendeeStepData.filter((d) => selectedProctors.includes(d.proctorUserId));
      setTableData(filteredProctors);
    } else {
      setTableData(attendeeStepData);
    }
  }, [currentProctors]);
  if (!sessions) return <EmptyState description="No data yet" icon={<CalendarDaysIcon />} />;

  return (
    <div>
      <table className="min-w-max text-xs h-full">
        <thead className="border-b border-slate-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
            >
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-start p-2 px-4">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 px-4 break-normal">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendeeStepTable;
