import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SLOTS } from '../queries.graphql';
import moment from 'moment';
import Element from './Element';
import Slot from 'components/dashboard/dashboard/events/slots';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import Slots from 'components/dashboard/dashboard/events/slots';
import AttendeeStatus from 'components/dashboard/dashboard/attendeeStatus/AttendeeStatus';
import { countBy } from 'lodash';
import AttendeeStepTable from 'components/dashboard/dashboard/attendeeStepTable/AttendeeStepTable';
import ProctorList from './proctorsList/ProctorList';
import useDashboardSlots from "hooks/useDashboardSlots";
import useDashboardParticipants from 'hooks/useDashboardParticipants';
import NotConnected from './notConnected/NotConnected';
import DetailedHeader from 'common/headers/DetailedHeader';
import Header from 'common/headers/Header';
import AttendeeStuck from './attendeeStuck/AttendeeStuck';

const MonitoringBoard: React.FC = () => {
  const { currentSlot, candidates, proctors } = useContext<DashboardContextType>(DashboardContext);
  const { loading, error } = useDashboardSlots();
  useDashboardParticipants();
  if (error) {
    return (
      <div>Something went wrong</div>
    );
  }
  return (
    <div className="flex flex-col w-full h-full space-y-3 text-gray">
      <div className="flex w-full h-1/3 space-x-3">
        <div className="flex w-1/5">
          <Element header={<Header header='Events' />} error={error} loading={loading} emptyMessage="No events yet">
            <Slots />
          </Element>
        </div>
        <div className="flex w-2/5">
          <Element
            header={<Header header={`Attendee Status (${candidates.length} Invited)`} />}
            loading={loading}
            emptyMessage="No status yet"
          >
            {currentSlot && <AttendeeStatus invited={candidates.length} />}
          </Element>
        </div>
        <div className="flex w-2/5">
          <Element
            header={
              <div className='text-xs flex text-red-700 space-x-1 font-semibold'>
                <div className='p-2 bg-red-700 rounded-full'></div>
                <div>Not Connected</div>
              </div>
            }
            loading={loading}
            emptyMessage="No events yet"
          >
            <NotConnected />
          </Element>
        </div>
      </div>
      <div className="flex flex-row w-full h-2/3 space-x-3">
        <div className="flex flex-col w-1/5 space-y-3">
          <Element
            header={
              <DetailedHeader mainCount={proctors.length} mainHeader='Proctors Offline' subHeader='00 Proctors Online' subHeaderColor='text-emerald-700'  />
            }
            loading={false}
            emptyMessage="No events yet"
          >
            <ProctorList />
          </Element>
          {/* <Element
            header={
              <DetailedHeader mainCount={26} mainHeader='Attendees Stuck' subHeader='for more than 1 min' subHeaderColor='text-red-900'  />
            }
            loading={false}
            emptyMessage="No events yet"
          >
              <AttendeeStuck />
          </Element> */}
        </div>
        <div className="flex h-full w-4/5">
          <Element loading={false} emptyMessage="No data yet">
            <AttendeeStepTable />
          </Element>
        </div>
      </div>
    </div>
  );
};

export default MonitoringBoard;
