import React, { useState } from 'react';
import { Slot } from 'hooks/useDashboardSlots';
import { Candidate, Proctor } from 'hooks/useDashboardParticipants';

export type ContextType = {
  sidebarOpen: boolean;
  updateSidebarOpen: (data: boolean) => void;
  selectedOption: string;
  updateOptionSelection: (data: string) => void;
  slots: Slot[];
  updateSlots: (data: Slot[]) => void;
  currentSlot: Slot;
  setCurrentSlot: React.Dispatch<React.SetStateAction<Slot>>;
  candidates: Candidate[];
  setCandidates: React.Dispatch<React.SetStateAction<Candidate[]>>;
  proctors: Proctor[];
  setProctors: React.Dispatch<React.SetStateAction<Proctor[]>>;
  currentProctors: Proctor[];
  setCurrentProctors: React.Dispatch<React.SetStateAction<Proctor[]>>;
  sessions: any[];
  setSessions: React.Dispatch<any>;
  attendeeStepData: any[];
  setAttendeeStepData: React.Dispatch<React.SetStateAction<any[]>>;
  alerts: any[];
  setAlerts: React.Dispatch<React.SetStateAction<any[]>>;

  selectedSlot: number;
  updateSelectedSlot: (id: number) => void;
  selectedProctors: number[];
  setSelectedProctors: React.Dispatch<number[]>;
};

type Props = React.PropsWithChildren;

export const DashboardContext = React.createContext<ContextType | null>(null);

export const DashboardProvider: React.FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('dashboard');
  const [slots, setSlots] = useState<Slot[]>([]);
  const [currentSlot, setCurrentSlot] = useState<Slot>(null);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [proctors, setProctors] = React.useState<Proctor[]>([]);
  const [currentProctors, setCurrentProctors] = React.useState<Proctor[]>([]);
  const [sessions, setSessions] = React.useState<any>([]);
  const [attendeeStepData, setAttendeeStepData] = React.useState([]);
  const [alerts, setAlerts] = React.useState<any[]>([]);


  const [selectedSlot, setSelectedSlot] = React.useState<number | null>(null);
  const [selectedProctors, setSelectedProctors] = React.useState<number[]>([]);

  const updateSidebarOpen = (data: boolean) => {
    setSidebarOpen(data);
  };
  const updateOptionSelection = (data: string) => {
    setSelectedOption(data);
  };
  const updateSlots = (data: Slot[]) => {
    setSlots(data);
  };
  const updateSelectedSlot = (id: number) => {
    setSelectedSlot(id);
  };
  return (
    <DashboardContext.Provider
      value={{
        sidebarOpen,
        updateSidebarOpen,
        selectedOption,
        updateOptionSelection,
        slots,
        updateSlots,
        currentSlot,
        setCurrentSlot,
        candidates,
        setCandidates,
        proctors,
        setProctors,
        currentProctors,
        setCurrentProctors,
        sessions,
        setSessions,
        attendeeStepData,
        setAttendeeStepData,
        alerts,
        setAlerts,

        selectedSlot,
        updateSelectedSlot,
        selectedProctors,
        setSelectedProctors,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
export default DashboardProvider;
