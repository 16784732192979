import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
  status: string;
  name: string;
  details: any;
};
const AttendeeCell: React.FC<Props> = (props) => {
  const redirectURL = `${process.env.PROCTOR_NG_URL}/slot?slot_id=${props.details.slotId}&event_id=${props.details.slot_id}`;
  return (
    <Link to={redirectURL} className="flex p-2 space-x-2 items-cente">
      <div
        className={classNames(
          'h-3 w-3 rounded-full',
          props.status === 'online' ? 'bg-emerald-500' : 'bg-red-500',
        )}
      ></div>
      <div>{props.name}</div>
    </Link>
  );
};

export default AttendeeCell;
