import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import AttendeeCell from './AttendeeCell';
import {
  BellIcon,
  CameraIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  IdentificationIcon,
  MicrophoneIcon,
  PhoneIcon,
  SpeakerWaveIcon,
  UserCircleIcon,
  VideoCameraIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

export type Data = {
  id: string;
  attendee: string;
  proctor: string;
  proctorUserId: number;
  status: string;
  attendeeId: number;
  participantGroupId: number;
  primaryCamera: React.ReactNode;
  secondaryCamera: React.ReactNode;
  chat: React.ReactNode;
  call: React.ReactNode;
  verification: React.ReactNode;
  testEnv: React.ReactNode;
  idCard: React.ReactNode;
  photo: React.ReactNode;
  audioTest: React.ReactNode;
  screenShare: React.ReactNode;
  notificationPermission: React.ReactNode;
  webcamPermission: React.ReactNode;
  microphonePermission: React.ReactNode;
  slotId: number;
};

const columnHelper = createColumnHelper<Data>();

export const columns = [
  columnHelper.accessor((row) => row.attendee, {
    id: 'attendee',
    cell: (info) => (
      <AttendeeCell
        details={info.row.original}
        status={info.row.original.status}
        name={info.getValue()}
      />
    ),
    header: () => <div className="truncate text-xs font-semibold w-40">Attendees</div>,
  }),
  columnHelper.accessor((row) => row.proctor, {
    id: 'proctor',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <div className="truncate text-xs font-semibold w-40">Proctor</div>,
  }),
  columnHelper.accessor((row) => row.primaryCamera, {
    id: 'primaryCamera',
    cell: (info) => info.getValue(),
    header: () => (
      <div className="min-w-10">
        <VideoCameraIcon className="h-5 w-5" />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.secondaryCamera, {
    id: 'secondaryCamera',
    cell: (info) => info.getValue(),
    header: () => <DevicePhoneMobileIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.chat, {
    id: 'chat',
    cell: (info) => info.getValue(),
    header: () => <ChatBubbleLeftRightIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.call, {
    id: 'call',
    cell: (info) => info.getValue(),
    header: () => <PhoneIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.screenShare, {
    id: 'screenShare',
    cell: (info) => info.getValue(),
    header: () => <ComputerDesktopIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.verification, {
    id: 'verification',
    cell: (info) => info.getValue(),
    header: () => <CheckBadgeIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.testEnv, {
    id: 'testEnv',
    cell: (info) => info.getValue(),
    header: () => <EyeIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.photo, {
    id: 'photo',
    cell: (info) => info.getValue(),
    header: () => <UserCircleIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.idCard, {
    id: 'idCard',
    cell: (info) => info.getValue(),
    header: () => <IdentificationIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.audioTest, {
    id: 'audioTest',
    cell: (info) => info.getValue(),
    header: () => <SpeakerWaveIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.notificationPermission, {
    id: 'notificationPermission',
    cell: (info) => info.getValue(),
    header: () => <BellIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.webcamPermission, {
    id: 'webcamPermission',
    cell: (info) => info.getValue(),
    header: () => <CameraIcon className="h-5 w-5" />,
  }),
  columnHelper.accessor((row) => row.microphonePermission, {
    id: 'microphonePermission',
    cell: (info) => info.getValue(),
    header: () => <MicrophoneIcon className="h-5 w-5" />,
  }),
];
