import React, { useContext, useState } from 'react';
import Checkbox from 'common/components/Checkbox';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import EmptyState from 'common/components/EmptyState';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const ProctorList: React.FC = () => {
  const { proctors, currentProctors, setCurrentProctors } = useContext<DashboardContextType>(DashboardContext);
  if (!proctors || !proctors.length) {
    return <EmptyState description="No data found" icon={<UserCircleIcon className="h-5 w-5" />} />;
  }
  const handleProctorSelection = (event: React.ChangeEvent<HTMLInputElement>, userId: number) => {
    const selected = currentProctors.find((proctor) => proctor.id === userId);
    if (selected) {
      const newData = currentProctors.filter((proctor) => proctor.id !== userId);
      setCurrentProctors(newData);
    } else {
      setCurrentProctors([...currentProctors, proctors.find((proctor) => proctor.id === userId)]);  
    }
  };

  return (
    <div className="flex flex-col">
      {proctors.map((proctor) => (
        <div
          key={proctor.id}
          className="flex flex-row justify-between items-center w-full p-1 hover:cursor-pointer"
        >
          <div className="flex flex-col w-full truncate">
            <div className="text-[12px] font-semibold">{proctor.email}</div>
          </div>
          <Checkbox
            id={proctor.id}
            name={proctor.username}
            size="sm"
            onChange={(event) => handleProctorSelection(event, proctor.id)}
            checked={currentProctors.filter((cp) => cp.id === proctor.id).length > 0}
            disabled={false}
          />
        </div>
      ))}
    </div>
  );
};

export default ProctorList;
