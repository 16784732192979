import React, {useContext, useEffect, useState} from 'react';
import {ContextType as DashboardContextType, DashboardContext} from "contexts/DashboardContext";
import {useQuery} from "@apollo/client";
import {GET_SLOTS} from "components/dashboard/queries.graphql";
import moment from "moment/moment";

export interface Participant {
  id: number;
  participant_group_id: number;
  role: string;
  user_id: number;
}
export interface Slot {
  event: {
    id: number;
    name: string;
  };
  participant: Participant[];
  starts_at: string;
  ends_at: string;
  id: number;
}
interface SlotView {
  views_slot: Slot[];
}

export interface SlotRequestArgument {
  startsAt: string;
  endsAt: string;
}

const useDashboardSlots = () => {
  const { updateSlots, setCurrentSlot } = useContext<DashboardContextType>(DashboardContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { error, data } = useQuery<SlotView, SlotRequestArgument>(GET_SLOTS, {
    variables: {
      startsAt: moment().subtract(5, 'day').startOf('day').toISOString(),
      endsAt: moment().endOf('day').toISOString(),
    },
  });
  if (error) setLoading(false);
  useEffect(() => {
    if (data && data.views_slot && data.views_slot.length) {
      updateSlots(data.views_slot);
      setCurrentSlot(data.views_slot[0]);
      setLoading(false);
    }
  }, [data]);
  return { loading, error };
}

export default useDashboardSlots;
