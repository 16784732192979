import React from 'react';
import { CalendarDaysIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Loading from '../../../common/components/Loading';
import EmptyState from '../../../common/components/EmptyState';

type Props = React.PropsWithChildren & {
  header?: React.ReactNode;
  loading?: boolean;
  empty?: boolean;
  emptyMessage: string;
  error?: any;
};

const Element: React.FC<Props> = (props) => {
  const renderBody = (): React.ReactNode => {
    if (props.error) return (
      <div className="flex flex-col w-full h-full justify-center items-center space-y-4">
        <ExclamationTriangleIcon className="h-10 w-10 text-red-700" />
        <div className="text-xs">Something went wrong. Please try again, or contact the admin.</div>
      </div>
    );
    if (props.loading) return <Loading />;
    if (props.empty) return (
      <EmptyState
        icon={<CalendarDaysIcon className="h-10 w-10" />}
        description={props.emptyMessage}
      />
    );
    return props.children;
  }
  return (
    <div className="flex flex-col rounded w-full h-full bg-white p-2 border border-solid border-gray-300 space-y-1">
      {props.header && (
        <div className="flex flex-col justify-center w-full border-b border-gray-200 pb-2">
          <div className="flex w-full space-x-1 items-center">
            <div>{props.header}</div>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full h-full overflow-y-scroll">
        {renderBody()}
      </div>
    </div>
  );
};

export default Element;
